import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import HomeAdmin from "./pages/HomeAdmin";
import AddItem from "./pages/AddItem";
import { Box } from "@chakra-ui/react";
import MenuPage from "./pages/Menupage";

function App() {
  return (
    <Router>
      <Box bgColor={"#f7f7f5"}>
        <Routes>
          <Route path="/" element={<MenuPage />}></Route>
          <Route path="/admin/login" element={<Login />}></Route>
          <Route path="/admin/home" element={<HomeAdmin />}></Route>
          <Route path="/admin/add" element={<AddItem />}></Route>
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
