import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Grid,
  GridItem,
  Icon,
  Flex,
  Image,
} from "@chakra-ui/react";
import { fire } from "../firebaseConfig";
import { StarIcon } from "@chakra-ui/icons";
import star_bella from "../star_bella.png";
import star_red_good from "../star_red_good.png";
import star_wax from "../start-wax.png";
import star_multi from "../star_multicolor.png";
import america_flag from "../united-states.png";
const MenuPage = () => {
  const [items, setItems] = useState([]);
  const [itemSativa, setItemSativa] = useState([]);
  const [dry, setDry] = useState([]);
  const [extracts, setExtracts] = useState([]);

  useEffect(() => {
    const unsubscribeIndica = fire
      .collection("variedad")
      .where("tipo", "==", "Indica")
      .orderBy("prezzo", "asc")
      .onSnapshot((res) => {
        let arrayItem = [];
        res.docs.forEach((result) => {
          arrayItem.push(result.data());
        });
        setItems(arrayItem);
      });
    const unsubscribeSativa = fire
      .collection("variedad")
      .where("tipo", "==", "Sativa")
      .orderBy("prezzo", "asc")
      .onSnapshot((res) => {
        let arrayItem = [];
        res.docs.forEach((result) => {
          arrayItem.push(result.data());
        });
        setItemSativa(arrayItem);
      });
    const unsubscribeExtract = fire
      .collection("variedad")
      .where("tipo", "==", "Extract")
      .orderBy("prezzo", "asc")
      .onSnapshot((res) => {
        let arrayItem = [];
        res.docs.forEach((result) => {
          arrayItem.push(result.data());
        });
        setExtracts(arrayItem);
      });
    const unsubscribeHash = fire
      .collection("variedad")
      .where("tipo", "==", "Hash")
      .orderBy("prezzo", "asc")
      .onSnapshot((res) => {
        let arrayItem = [];
        res.docs.forEach((result) => {
          arrayItem.push(result.data());
        });
        setDry(arrayItem);
      });
    return () => {
      // Unsubscribe quando il componente viene smontato
      unsubscribeSativa();
      unsubscribeHash();
      unsubscribeIndica();
      unsubscribeExtract();
    };
  }, []);
  const gradientBackground = {
    background:
      "linear-gradient(to bottom, #CFCFCF 8%, #FFFFFF 50%, #FFFFFF 58%, #F0F0F0 71%, #E0E0E0 100%)",
  };

  const titleFont = {
    fontFamily: "docalisme",
    color: "white", // Colore del testo bianco
    borderBottom: "1px solid white", // Bordo sottile nero
    paddingBottom: "10px", // Spazio tra il testo e il bordo
    WebkitTextStroke: "0.5px black", // Contorno nero
    textAlign: "center",
    fontSize: "30px",
  };
  const containerStyle = {
    overflow: "hidden", // Nasconde il contenuto che esce dal contenitore
    height: "100vh", // Altezza al 100% della viewport
  };
  const listFont = {
    fontFamily: "CroissantOne-Regular",
    listStyle: "none", // Rimuovi i pallini dalla lista
    padding: "0", // Rimuovi il padding della lista
    margin: "0", // Rimuovi il margin della lista
    color: "white", // Colore del testo bianco
    fontSize: "12px",
  };
  const gridStyle = {
    padding: "0.2em",
    border: "1px solid black",
    boxShadow: "1px 2px 9px #000000",
    borderRadius: "1em",
    height: "95vh",
    background:
      "linear-gradient(to bottom, #4c8d17 0%, #4c971a 50%, #358922 58%)",
  };
  const gridStyle2 = {
    padding: "0.2em",
    border: "1px solid black",
    boxShadow: "1px 2px 9px #000000",
    borderRadius: "1em",
    height: "95vh",
    background:
      "linear-gradient(to bottom, #4c8d17 0%, #4c971a 50%, #358922 58%)",
  };
  const gridStyle3 = {
    padding: "0.2em",
    border: "1px solid black",
    boxShadow: "1px 2px 9px #000000",
    borderRadius: "1em",
    height: "22vh",
    background:
      "linear-gradient(to bottom, #4c8d17 0%, #4c971a 50%, #358922 58%)",
  };
  const glowingAnimation = {
    animation: "glow 4s infinite", // Durata e ripetizione dell'animazione
  };
  const listStyle = {
    backgroundColor: "#245a14",
    padding: "0.1em",
    textAlign: "center",
    borderRadius: "0.3em",
    border: "0.2px solid white",
    boxShadow: "0 0 0px 0px white",
    animation: "glow 4s infinite",
  };

  const listStyleGold = {
    backgroundColor: "#245a14",
    padding: "0.1em",
    textAlign: "center",
    borderRadius: "0.3em",
    border: "0.2px solid white",
    boxShadow: "0 0 0px 0px white",
    animation: "glow 4s infinite",
  };

  const generateStars = (numStars) => {
    const stars = [];
    const marginStar = "0.1em";
    for (let i = 0; i < numStars; i++) {
      stars.push(
        <Icon
          key={i}
          as={StarIcon}
          alignSelf="flex-end"
          color="yellow"
          boxSize={3} // Imposta la dimensione desiderata
          marginRight={marginStar}
        />
      );
    }
    return stars;
  };
  const stars5 = generateStars(1);
  const stars7 = generateStars(2);
  const stars8 = generateStars(3);
  const stars10 = generateStars(4);
  const stars15 = generateStars(5);
  const stars20 = generateStars(6);

  // Rendi una griglia con una colonna
  const renderGrid = (array) => {
    const rows = Math.ceil(array.length / 1); // Calcola il numero di righe necessarie

    let gridTemplateRows = ""; // Costruisci la stringa per le righe della griglia

    for (let i = 0; i < rows; i++) {
      gridTemplateRows += "1fr "; // Crea una riga con altezza uguale
    }

    return (
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(2, 1fr)",
        }}
        templateRows={gridTemplateRows} // Applica il template delle righe
        gap="11px"
        alignItems="flex-start"
      >
        {array.map((data, index) => (
          <GridItem key={index}>
            <ul style={listFont}>
              <li
                class={
                  data.america === "Si" ? "usa-stars" : "background-normal"
                }
                style={data.prezzo === 100 ? listStyleGold : listStyle}
              >
                <Flex justifyContent="space-between" alignItems="center">
                  {data.nome}

                  {data.prezzo === 5 && (
                    <Flex alignItems="center">{stars5}</Flex>
                  )}

                  {data.prezzo === 7 && (
                    <Flex alignItems="center">{stars7}</Flex>
                  )}
                  {data.prezzo === 8 && (
                    <Flex alignItems="center">{stars8}</Flex>
                  )}
                  {data.prezzo === 10 && (
                    <Flex alignItems="center">{stars10}</Flex>
                  )}
                  {data.prezzo === 15 && (
                    <Flex alignItems="center">{stars15}</Flex>
                  )}
                  {data.prezzo === 20 && (
                    <Flex alignItems="center">{stars20}</Flex>
                  )}
                  {data.prezzo === 30 && (
                    <Flex alignItems="center">
                      <Image w="15px" h="15px" src={star_red_good} />
                    </Flex>
                  )}
                  {data.prezzo === 35 && (
                    <Flex alignItems="center">
                      <Image w="15px" h="15px" src={star_bella} />
                    </Flex>
                  )}
                  {data.prezzo === 50 && (
                    <Flex alignItems="center">
                      <Image w="15px" h="15px" src={star_wax} />
                    </Flex>
                  )}
                  {data.prezzo === 60 && (
                    <Flex alignItems="center">
                      <Image w="15px" h="15px" src={star_wax} />
                    </Flex>
                  )}
                  {data.prezzo === 100 && (
                    <Flex alignItems="center">
                      <Image w="15px" h="15px" src={star_multi} />
                    </Flex>
                  )}
                </Flex>
              </li>
            </ul>
          </GridItem>
        ))}
      </Grid>
    );
  };

  return (
    <Box
      style={{ ...gradientBackground, ...containerStyle }} // Applica il gradiente come sfondo
      padding="10px"
    >
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(3, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap="9px"
        alignItems="flex-start" // Allinea gli elementi verso l'inizio
      >
        <GridItem style={gridStyle}>
          <Box>
            <Heading style={titleFont} as="h2" marginBottom="10px">
              Indica
            </Heading>
            <ul style={listFont}>{renderGrid(items)}</ul>
          </Box>
        </GridItem>
        <GridItem style={gridStyle}>
          <Box>
            <Heading style={titleFont} as="h2" marginBottom="10px">
              Sativa
            </Heading>
            <ul style={listFont}>{renderGrid(itemSativa)}</ul>
          </Box>
        </GridItem>
        <GridItem style={gridStyle2}>
          <Box>
            <Heading style={titleFont} as="h2" marginBottom="10px">
              Filtered Hash & Extracts
            </Heading>
            <ul style={listFont}>{renderGrid(dry)}</ul>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default MenuPage;
