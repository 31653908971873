import {
  Heading,
  Icon,
  useToast,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Flex,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdEuro } from "react-icons/md";
import { fire } from "../firebaseConfig";
import React, { useEffect, useState } from "react";
export default function BoxVariedadAdmin({
  id,
  tipo,
  nome,
  prezzo,
  prezzoVisita,
}) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [newNome, setNewNome] = useState(nome);
  const [newPrezzo, setNewPrezzo] = useState(prezzo);
  useEffect(() => {
    setNewNome(nome || "");
    setNewPrezzo(prezzo || "");
  }, [nome, prezzo]);
  const handleSaveChanges = () => {
    if (newNome !== "" || newPrezzo !== "") {
      fire
        .collection("variedad")
        .doc(id)
        .update({
          nome: newNome !== "" ? newNome : nome,
          prezzo: newPrezzo !== "" ? Number(newPrezzo) : prezzo,
        })
        .then(() => {
          toast({
            title: "Success",
            description: "Modifiche salvate con successo",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          onCloseModal();
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    } else {
      onCloseModal();
    }
  };

  const deleteItem = () => {
    fire
      .collection("variedad")
      .doc(id)
      .delete()
      .then(() => {
        onClose();
        toast({
          title: "Success",
          description: "Varieta eliminata con successo",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Errore",
          description: "Errore",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      });
  };

  return (
    <Flex justifyContent="space-between" alignItems="center" py={5}>
      <Heading fontSize={"md"} fontFamily={"body"} fontWeight={500}>
        {String(nome)?.toUpperCase()}
      </Heading>
      <Flex align="center">
        <Icon
          w={6}
          h={6}
          p={1}
          color="white"
          cursor="pointer"
          onClick={onOpenModal}
          as={MdEdit}
          backgroundColor={"orange"}
          mr="0.5em"
          borderRadius={"0.1em"}
        />
        <Icon
          w={6}
          h={6}
          p={1}
          color="white"
          cursor="pointer"
          onClick={onOpen}
          as={MdDelete}
          backgroundColor={"red"}
          mr="0.5em"
          borderRadius={"0.1em"}
        />
      </Flex>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Elimina varietá
            </AlertDialogHeader>

            <AlertDialogBody>
              Sei sicuro? Non potrai tornare indietro!
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteItem} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={isOpenModal} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h2" fontSize="24px" color="blue.500">
              Modifica Varietá
            </Heading>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input
                value={newNome}
                onChange={(e) => setNewNome(e.target.value)}
                placeholder="Nuovo nome"
                variant="filled"
                _focus={{ borderColor: "blue.500" }}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Prezzo</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={MdEuro} color="gray.300" />}
                />
                <Input
                  value={newPrezzo}
                  onChange={(e) => setNewPrezzo(e.target.value)}
                  placeholder="Nuovo prezzo"
                  variant="filled"
                  type="number"
                  _focus={{ borderColor: "blue.500" }}
                />
              </InputGroup>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleSaveChanges}
              _hover={{ bg: "blue.600" }}
            >
              Salva Modifiche
            </Button>
            <Button onClick={onCloseModal} variant="outline">
              Annulla
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
