import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
  Select,
} from "@chakra-ui/react";
import { useState } from "react";

import { fire } from "../firebaseConfig";

export default function AddItem() {
  const [nome, setNome] = useState("");
  const [tipo, setTipo] = useState("");
  const [prezzo, setPrezzo] = useState(0);
  const [prezzoVisita, setPrezzoVisita] = useState(0);
  const [america, setAmerica] = useState("");
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const toast = useToast();
  const handleAdd = () => {
    if (nome !== "" && tipo !== "") {
      fire
        .collection("variedad")
        .add({
          nome,
          tipo: capitalizeFirst(tipo),
          prezzo: Number(prezzo),
          prezzoVisita: Number(prezzoVisita),
          america: capitalizeFirst(america),
        })
        .then(() => {
          toast({
            title: "Success",
            description: "Varieta aggiunta con successo",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setNome("");
          setPrezzo("");
          setPrezzoVisita("");
          setTipo("");
          setAmerica("");
        })
        .catch(() => {
          toast({
            title: "Errore",
            description: "errore",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else {
      toast({
        title: "Errore",
        description: "Devi compilare tutti i campi",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleSelectChange = (event) => {
    setTipo(event.target.value);
  };
  const handleSelectChange2 = (event) => {
    setAmerica(event.target.value);
  };
  const options = ["Indica", "Sativa", "Hash", "Extract"]; // Opzioni della select
  const options2 = ["Si", "No"]; // Opzioni della select
  return (
    <Flex minH={"100vh"} bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack spacing={1} mx={"auto"} w="30em" py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Aggiungi varietá</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Nome</FormLabel>
              <Input
                type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Tipo</FormLabel>

              <Select
                placeholder="Seleziona una categoria"
                value={tipo}
                onChange={handleSelectChange}
                marginBottom="20px"
              >
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="password">
              <FormLabel>Cali?</FormLabel>

              <Select
                placeholder="Seleziona risposta"
                value={america}
                onChange={handleSelectChange2}
                marginBottom="20px"
              >
                {options2.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="password">
              <FormLabel>Prezzo</FormLabel>
              <Input
                type="number"
                value={prezzo}
                onChange={(e) => setPrezzo(e.target.value)}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Prezzo Visitante</FormLabel>
              <Input
                type="number"
                value={prezzoVisita}
                onChange={(e) => setPrezzoVisita(e.target.value)}
              />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={handleAdd}
              >
                Salva
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
