import { Box, Grid, GridItem, Heading, Select, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { fire } from "../firebaseConfig";
import BoxVariedadAdmin from "./BoxAdmin";

const ListItemsAdmin = () => {
  const [items, setItems] = useState([]);
  const [itemSativa, setItemSativa] = useState([]);
  const [extracts, setExtracts] = useState([]);
  const [dry, setDry] = useState([]);
  const [sortBy, setSortBy] = useState("prezzo"); // Definire l'ordinamento iniziale

  const titleFont = {
    fontFamily: "DelaGothicOne-Regular",
    color: "white", // Colore del testo bianco
    borderBottom: "1px solid black", // Bordo sottile nero
    paddingBottom: "10px", // Spazio tra il testo e il bordo
    WebkitTextStroke: "1px black", // Contorno nero
  };
  const listFont = {
    fontFamily: "CroissantOne-Regular",
    listStyle: "none", // Rimuovi i pallini dalla lista
    padding: "0", // Rimuovi il padding della lista
    margin: "0", // Rimuovi il margin della lista
    color: "black", // Colore del testo bianco
    WebkitTextStroke: "1px black", // Contorno nero
    fontSize: "22px",
  };

  useEffect(() => {
    const unsubscribeIndica = fire
      .collection("variedad")
      .where("tipo", "==", "Indica")
      .orderBy("prezzo", "asc")
      .onSnapshot((res) => {
        let arrayItem = [];
        res.docs.forEach((result) => {
          arrayItem.push({ data: result.data(), id: result.id });
        });
        setItems(arrayItem);
      });
    const unsubscribeSativa = fire
      .collection("variedad")
      .where("tipo", "==", "Sativa")
      .orderBy("prezzo", "asc")
      .onSnapshot((res) => {
        let arrayItem = [];
        res.docs.forEach((result) => {
          arrayItem.push({ data: result.data(), id: result.id });
        });
        setItemSativa(arrayItem);
      });
    const unsubscribeExtract = fire
      .collection("variedad")
      .where("tipo", "==", "Extract")
      .orderBy("prezzo", "asc")
      .onSnapshot((res) => {
        let arrayItem = [];
        res.docs.forEach((result) => {
          arrayItem.push({ data: result.data(), id: result.id });
        });
        setExtracts(arrayItem);
      });
    const unsubscribeHash = fire
      .collection("variedad")
      .where("tipo", "==", "Hash")
      .orderBy("prezzo", "asc")
      .onSnapshot((res) => {
        let arrayItem = [];
        res.docs.forEach((result) => {
          arrayItem.push({ data: result.data(), id: result.id });
        });
        setDry(arrayItem);
      });
    return () => {
      // Unsubscribe quando il componente viene smontato
      unsubscribeSativa();
      unsubscribeHash();
      unsubscribeIndica();
      unsubscribeExtract();
    };
  }, []);
  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };
  // Funzione per ordinare gli elementi in base alla scelta dell'utente
  const sortItems = (items, sortBy) => {
    if (sortBy === "nome") {
      return items.sort((a, b) => a.data.nome.localeCompare(b.data.nome));
    } else if (sortBy === "prezzo") {
      return items.sort((a, b) => a.data.prezzo - b.data.prezzo);
    }
    return items;
  };
  const gridStyle = {
    backgroundColor: "#f5f5f5",
    padding: "0.5em",
    borderRadius: "0.3em",
    boxShadow: "1px 1px 4px #000000",
    border: "1px solid white",
  };
  return (
    <>
      <Stack direction="row" marginBottom="20px">
        <Select value={sortBy} onChange={handleSortChange}>
          <option value="nome">Ordina per nome</option>
          <option value="prezzo">Ordina per prezzo</option>
        </Select>
      </Stack>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap="20px"
        alignItems="flex-start" // Allinea gli elementi verso l'inizio
      >
        <GridItem style={gridStyle}>
          <Box>
            <Heading
              style={titleFont}
              as="h2"
              fontSize="28px"
              marginBottom="10px"
            >
              Indica
            </Heading>
            <ul style={listFont}>
              {sortItems(items, sortBy).map((data) => (
                <BoxVariedadAdmin
                  id={data.id}
                  tipo={data.data.tipo}
                  nome={data.data.nome}
                  prezzo={data.data.prezzo}
                  prezzoVisita={data.data.prezzoVisita}
                />
              ))}
            </ul>
          </Box>
        </GridItem>
        <GridItem style={gridStyle}>
          <Box>
            <Heading
              style={titleFont}
              as="h2"
              fontSize="28px"
              marginBottom="10px"
            >
              Sativa
            </Heading>
            <ul style={listFont}>
              {sortItems(itemSativa, sortBy).map((data) => (
                <BoxVariedadAdmin
                  id={data.id}
                  tipo={data.data.tipo}
                  nome={data.data.nome}
                  prezzo={data.data.prezzo}
                  prezzoVisita={data.data.prezzoVisita}
                />
              ))}
            </ul>
          </Box>
        </GridItem>
        <GridItem style={gridStyle}>
          <Box>
            <Heading
              style={titleFont}
              as="h2"
              fontSize="28px"
              marginBottom="10px"
            >
              Hash/Dry
            </Heading>
            <ul style={listFont}>
              {sortItems(dry, sortBy).map((data) => (
                <BoxVariedadAdmin
                  id={data.id}
                  tipo={data.data.tipo}
                  nome={data.data.nome}
                  prezzo={data.data.prezzo}
                  prezzoVisita={data.data.prezzoVisita}
                />
              ))}
            </ul>
          </Box>
        </GridItem>
        <GridItem style={gridStyle}>
          <Box>
            <Heading
              style={titleFont}
              as="h2"
              fontSize="28px"
              marginBottom="10px"
            >
              Extracts
            </Heading>
            <ul style={listFont}>
              {sortItems(extracts, sortBy).map((data) => (
                <BoxVariedadAdmin
                  id={data.id}
                  tipo={data.data.tipo}
                  nome={data.data.nome}
                  prezzo={data.data.prezzo}
                  prezzoVisita={data.data.prezzoVisita}
                />
              ))}
            </ul>
          </Box>
        </GridItem>
      </Grid>
    </>
  );
};
export default ListItemsAdmin;
