import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
const firebaseConfig = {
  apiKey: "AIzaSyD-mIIbtEYccqkBUVadFAhix-5U7XojcYE",
  authDomain: "clubciro-5e05b.firebaseapp.com",
  projectId: "clubciro-5e05b",
  storageBucket: "clubciro-5e05b.appspot.com",
  messagingSenderId: "606130471611",
  appId: "1:606130471611:web:d1fa61d97723d9fdca9f9e",
  measurementId: "G-CJWS6NS012",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const fire = firebase.firestore();
const db = firebase.database();
const auth = firebase.auth();
export { storage, db, auth, fire };
