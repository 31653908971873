import { Button, HStack, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ListItemsAdmin from "../components/ListItemsAdmin";

const HomeAdmin = () => {
  const router = useNavigate();
  return (
    <Stack p="1em">
      <HStack spacing={"2"} mt="5em">
        <Button colorScheme={"green"} onClick={() => router("/admin/add")}>
          Aggiungi
        </Button>
      </HStack>
      <ListItemsAdmin />
    </Stack>
  );
};

export default HomeAdmin;
